import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Component/Header/Header';
import Orders from './Component/Order/Orders';
import Sidebar from './Component/Sidebar/Sidebar';
import NotificationSound from './assets/notification-sound.m4a';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenu } from './Component/MenuItem/Menuslice/menuslice';
import { useAblyChannel } from './Helper/ably/useAblyChannels';
import { fetchTableno, setUpdatedTableList } from './Component/MainContent/MainContentSlice';

function App() {
  const location = useLocation()
  const [activePath, setActivePath] = useState(location.pathname)
  const { tableno, tableOrderItem } = useSelector((state) => state.table);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTableno());
  }, [0]);  
  const [path,setPath] = useState('/')
  const hadleGetPath = (path)  =>{
        setPath(path)
        setActivePath(path);
  }
  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }
  console.log({ tableno })
  useAblyChannel("place_sub_order", (message) => {   
    playAudio();
    const findTable = tableno.find(i => i.table_id === message.name)
    const updatedTables = tableno.map(table => 
      table.table_id === message.name
      ? { ...table, orderStatus: 1 }  // Update orderStatus for the matching table
      : table  // Return other tables as-is
    );
    dispatch(setUpdatedTableList(updatedTables))
    console.log({ message, findTable })
    toast(`Order placed on table no - ${findTable.table_number}`)
  });
  useAblyChannel("update_sub_order", (message) => {   
    playAudio();
    const findTable = tableno.find(i => i.table_id === message.name)
    const updatedTables = tableno.map(table => 
      table.table_id === message.name
      ? { ...table, orderStatus: 1 }  // Update orderStatus for the matching table
      : table  // Return other tables as-is
    );
    dispatch(setUpdatedTableList(updatedTables))
    toast(`Order updated on table no - ${findTable.table_number}`)
  });

  return (
    <> 
        <Header />
        <div className='mainContainer'>
           <Sidebar hadleGetPath={hadleGetPath} activePath={activePath}/>
          <div className='main'>
             <Orders path={path} />
          </div>
          <ToastContainer />
        </div>
        <audio ref={audioPlayer} src={NotificationSound} />
        {/* <Routes>
          <Route path='/' element={<></>}/>
        </Routes>
        <Orders/> */}
    </>
  );
}

export default App;

