import React, { useEffect, useState } from "react";
import "./MainContent.css";
import people from "../../assets/people.svg";
import redtag from "../../assets/redtag.svg";
import yelloworder from "../../assets/yelloworder.svg";
import veg from "../../assets/veg.svg";
import nonveg from "../../assets/nonveg.svg";
import egg from "../../assets/egg.svg";
import add from "../../assets/add.svg";
import tick from "../../assets/tick.svg";
import remove from "../../assets/remove.svg";
import modaluser from "../../assets/modaluser.svg";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDOMServer from 'react-dom/server';
import {
  fetchTableOrderItem,
  fetchTableno,
  setAllOrders,
  setAllPastOrders,
  setUpdatedTableList,
} from "./MainContentSlice";
import {
  gettabledata,
  getTablesPastOrders,
  ordercomplete,
  orderpunch,
} from "./action";
import PastOrder from "./PastOrder";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import PrintKOT from "../PrintKOT/PrintKOT";

function MainContent() {
  const dispatch = useDispatch();
  const { tableno, tableOrderItem } = useSelector((state) => state.table);
  const {menu} = useSelector(state=>state.food)
  const [tabledata, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [showCompleteModal, stShowCompleteModal] = useState(false);
  const [showKOT, setShowKOT] = useState(false);
  const [tabledataid, setTabledataid] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [pastOrdersList, setPastOrdersList] = useState([]);
  useEffect(() => {
    dispatch(fetchTableno());
  }, [0]);

  console.log({ tabledata });

  useEffect(() => {
    if (tableOrderItem) {
      setTableData(tableOrderItem);
      const check = tableOrderItem?.order_info?.some((item) => item.is_punched);
      if (check) {
        let pastOrder = [];
        if (
          tableOrderItem?.order_info &&
          Array.isArray(tableOrderItem.order_info)
        ) {
          for (const order of tableOrderItem.order_info) {
            if (order?.is_punched) {
              pastOrder.push(order);
            }
          }
        }
        dispatch(setAllPastOrders(pastOrder));
      }
    }
  }, [tableOrderItem]);

  const handleClose = () => {
    setShow(false);
    setSubtotal(0);
    setTableData([]);
    setPastOrdersList([]);
  };

  const handleshow = (tableId) => {
    console.log(tableId);
    setShow(true);
    setTabledataid(tableId);
    if (tableId) {
      gettabledata(tableId.table_id).then((res) => {
        if (res) {
          setTableData(res);
          calculate(res);
        }
      });
    }
  };

  const [count, setCount] = useState(1);

  const handaleAdd = (qty) => {
    setCount((prev) => prev + 1);
  };

  const handleRemove = (qty) => {
    setCount((prev) => prev - 1);
  };

  const calculate = (data) => {
    let total = 0;
    let totalDiscount = 0;
    if (
      data?.order_info &&
      Array.isArray(data.order_info) &&
      data.order_info.length > 0
    ) {
      const subtotal = data.order_info
        .filter((subOrder) => !subOrder.is_punched)
        .map((subOrder) => {
          console.log({ subOrder });
          totalDiscount += subOrder.subOrder_discount;
          total = subOrder.subOrder_total - subOrder.subOrder_discount;
        })
        .reduce((acc, curr) => acc + curr, 0);

      // setSubtotal(subtotal);
    }
    return { total, totalDiscount };
  };
  const calculateAllOrders = (data) => {
    let total = 0;
    let totalDiscount = 0;
    if (
      data?.order_info &&
      Array.isArray(data.order_info) &&
      data.order_info.length > 0
    ) {
      const subtotal = data.order_info
        .map((subOrder) => {
          console.log({ subOrder });
          totalDiscount += subOrder.subOrder_discount;
          total += subOrder.subOrder_total;
        })
        .reduce((acc, curr) => acc + curr, 0);

      // setSubtotal(subtotal);
    }
    return total - totalDiscount;
  };

  const calculateCompleteDiscount = (data) => {
    let total = 0;
    let totalDiscount = 0;
    data?.order_info?.map((subOrder) => {
      console.log({ subOrder });
      totalDiscount += subOrder.subOrder_discount;
      total += subOrder.subOrder_total;
    })
    return { total, totalDiscount, totalPainAmount: total - totalDiscount}

  }

  const handleOrderComplete = async () => {
    const header = {
      table_id: tabledataid.table_id,
      order_id: tabledata?.order_id,
    };
    try {
      const response = await ordercomplete(header);
      const updatedTables = tableno.map(table => 
        table.table_id === tabledataid.table_id
        ? { ...table, orderStatus: 0 }  // Update orderStatus for the matching table
        : table  // Return other tables as-is
      );
      dispatch(setUpdatedTableList(updatedTables))
      // toast.success(response.data.message);
      stShowCompleteModal(true);
      // setTableData([])
      // setPastOrdersList([])
      // setShow(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCloseModalCompleteModal = () => {
    stShowCompleteModal(false);
    setTableData([]);
    setPastOrdersList([]);
    setShow(false);
  };
  const handleFetchPastOrders = () => {
    getTablesPastOrders(tabledataid?.table_id).then((resp) => {
      setPastOrdersList(resp.map((i) => i.order_info));
    });
  };

//   const handlePrint = (data) => {
//     console.log({ data })
//     const componentHTML = ReactDOMServer.renderToString(<PrintKOT data={data} />);
//     const printKot = document.getElementById('printKot')
//     console.log({ componentHTML, printKot })
//     const printWindow = window.open('', '_blank', 'height=600,width=800');
//     printWindow.document.write(`<html><head><title>Print</title><style>
//     body { width: 315px; margin: 0 auto; }
//   </style></head><body>${componentHTML}</body></html>`);
//   return
//     printWindow.document.close();
//     printWindow.focus();
//     printWindow.print();
//     printWindow.close();
//   };
const handlePrint = () => {
  const componentHTML = ReactDOMServer.renderToString(
    <PrintKOT data={unpunchedItems} tableNo={tabledataid.table_number} menu={menu}/>
  );

  // Create a temporary element to calculate height
  const tempDiv = document.createElement("div");
  tempDiv.style.width = "283px";
  tempDiv.style.visibility = "hidden";
  tempDiv.innerHTML = componentHTML;
  document.body.appendChild(tempDiv);

  // Calculate the height of the content
  const height = tempDiv.clientHeight;
  document.body.removeChild(tempDiv);

  // Open a new print window with the dynamically calculated height
  const printWindow = window.open("", "_blank", `width=400,height=${height}`);
  printWindow.document.write(`
    <html>
    <head>
      <title>Print KOT</title>
      <style>
        body { 
          width: 283px; 
          margin: 0 auto;
          font-size: 12px; /* Adjust font size as needed */
        }
        @media print {
          body {
            width: 283px;
            height: ${height}px;
            margin: 0 auto;
          }
        }
      </style>
    </head>
    <body>${componentHTML}</body>
    </html>
  `);
  printWindow.document.close();
  printWindow.focus();
  setTimeout(() => {
    printWindow.print();
    printWindow.close();
  }, 500);
};


  const handleOrderPunch = async () => {
    let updateCart = tabledata;
    console.log({ unpunchedItems })
    // return
    if (tabledata && tabledata?.order_info) {
        const unpunchedSubOrderIds = tabledata?.order_info
        .filter((order) => !order.is_punched)
        .map((order) => order.sub_order_id);
        if(unpunchedSubOrderIds) {
            handlePrint(unpunchedItems)
          const header = {
            table_id: tabledataid.table_id,
            order_id: tabledata?.order_id,
            sub_order_id: unpunchedSubOrderIds[0],
          };
          try {
              const response = await orderpunch(header);
              if (response) {
              updateCart.order_info.map((order) => {
                if (!order.is_punched) {
                  order.is_punched = true;
                }
              });
              setTableData([]);
              setSubtotal(0);
              dispatch(setAllOrders(updateCart));
              toast.success(response.data.message);
            }
          } catch (error) {
            toast.error(error.message);
          }
        }
    }
  };

  const unpunchedItems =
    tabledata?.order_info?.filter((item) => !item.is_punched) || [];
  const punchedItems =
    tabledata?.order_info?.filter((item) => item.is_punched) || [];

  useEffect(() => {}, [tabledata]);

  console.log({tableno ,tableOrderItem, tabledata})

  return (
    <>
      <div className="main-content">
        {tableno &&
          tableno.map((item, index) => (
            <div
              className={item.orderStatus === 1 ? "table-bg-yellow" : "table-bg"}
              onClick={() => handleshow(item)}
              key={index}
            >
              <div className="table tableinfo">
                <img src={yelloworder} alt="Order" />
                <div className="table-text">
                  <h2>{item.table_number}</h2>
                  {/* <div className="tableinnerinfo">
                                    <p>Shreyam</p>
                                    <div className="people">
                                        <img src={people} alt="People" />
                                        <h5>4</h5>
                                    </div>
                                </div> */}
                </div>
              </div>
              {/* <div className="time">
                            <h5>00:30:00</h5>
                            <h6>4:10 pm</h6>
                        </div> */}
              <img src={redtag} alt="Red Tag" />
            </div>
          ))}
      </div>

      <Modal
        id="table-order"
        show={show}
        onHide={handleClose}
        className="singleitem right-to-left"
      >
        <Modal.Header closeButton>
          <div className="table-top">
            <div className="table-info">
              <h3>{tabledataid.table_number}</h3>
              <div className="modaltableinnerinfo">
                <p>{tabledata?.customer_name}</p>
                <p>Pax: {tabledata?.pax}</p>
                {/* <div className="modalpeople">
                                    <img src={modaluser} alt="Modal User" />
                                    <h5>4</h5>
                                </div> */}
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="guestselectmodalmain pt-0">
            <div className="itemtitle margin">
              <div className="order">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Current Order</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordianinfo-bg">
                        {unpunchedItems.length > 0 &&
                          unpunchedItems.map((item, index) => (
                            <div className="accordianinfo" key={index}>
                                {console.log({ item })}
                              {Array.isArray(item?.items) &&
                                item?.items?.map((subItem, subIndex) =>
                                  ["None", "Checkout"].includes(
                                    subItem.combo
                                  ) ? (
                                    <div
                                      key={subIndex}
                                      className="accordiabsubIteminfo"
                                    >
                                        
                                      <div className="accordiabinfo-left">
                                        <div className="order-name">
                                          {console.log({ subItem })}
                                        <img
                                            src={subItem?.items[0]?.diet === "N" ? nonveg : subItem?.items[0]?.diet === "V" ? veg : egg}
                                            alt="Non-Veg"
                                        />
                                          {/* {subItem.diet === "N" && (
                                            <img src={nonveg} alt="Non-Veg" />
                                          )}
                                          {subItem.diet === "V" && (
                                            <img src={veg} alt="Veg" />
                                          )}
                                          {subItem.diet === "E" && (
                                            <img src={egg} alt="Egg" />
                                          )} */}
                                          <h5>{subItem?.items[0]?.item_name}</h5>
                                        </div>
                                        <div className="order-prize">
                                          <p>₹{subItem?.price}</p>
                                        </div>
                                        {subItem?.items[0]?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {subItem?.items[0]?.add_ons?.map((i, inx) => (
                                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                    <span> - Price: {i.price}</span>
                                                </p>
                                            ))}
                                            </div>}
                                        {!isEmpty(subItem?.items[0]?.options) && <div className="OrderDetailsSubInfo">
                                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {subItem?.items[0]?.options && Object.entries(subItem.items[0].options).map(([optionName, optionDetails], idx) => (
                                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                                <span>{optionName}:</span> 
                                                <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                <span> - Price: {optionDetails.price}</span>
                                            </p>
                                            ))}
                                        </div>}
                                      </div>
                                      <div className="accordiabinfo-right">
                                        <div className="countitems">
                                          {/* <div onClick={() => handleRemove(subItem?.qty)}><img src={remove} alt="Remove" /></div> */}
                                          <p>{subItem?.qty}</p>
                                          {/* <div onClick={() => handaleAdd(subItem?.qty)}><img src={add} alt="Add" /></div> */}
                                        </div>
                                        <div className="order-prize">
                                          <p>₹{subItem?.price}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="combo-page">
                                      <div className="combo-info">
                                        {subItem.items &&
                                          subItem.items.map((i, xi) => (
                                            <div
                                              key={xi}
                                              className="accordiabsubIteminfo"
                                            >
                                              <div className="accordiabinfo-left">
                                                <div className="order-name">
                                                  <img
                                                    src={i.diet === "N" ? nonveg : i.diet === "V" ? veg : egg}
                                                    alt="Non-Veg"
                                                  />
                                                  <h5>{i?.item_name}</h5>
                                                </div>
                                                <div className="order-prize">
                                                  <p>₹{i?.price}</p>
                                                </div>
                                                {i?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {i?.add_ons?.map((i, inx) => (
                                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                    <span> - Price: {i.price}</span>
                                                </p>
                                            ))}
                                            </div>}
                                        {!isEmpty(i?.options) && <div className="OrderDetailsSubInfo">
                                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {i?.options && Object.entries(subItem.items[0].options).map(([optionName, optionDetails], idx) => (
                                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                                <span>{optionName}:</span> 
                                                <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                <span> - Price: {optionDetails.price}</span>
                                            </p>
                                            ))}
                                        </div>}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                      <div className="combo-total">
                                        <div className="accordiabinfo-right">
                                          <div className="countitems">
                                            <p>{subItem?.qty}</p>
                                          </div>
                                          <div className="order-prize">
                                            <p>
                                              ₹
                                              {(subItem.price -
                                                subItem.discount) *
                                                subItem.qty}{" "}
                                              <del>{subItem.price}</del>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="horizontal-line"></div>
            {console.log({ punchedItems })}
            <PastOrder punchedItems={punchedItems} isTable={false} />
            {pastOrdersList.length > 0 && (
              <>
              {console.log({ pastOrdersList })}
                <h3>All past orders</h3>
                {pastOrdersList.map((order, key) => (
                  <PastOrder
                    punchedItems={order}
                    key={key}
                    isTable={true}
                    orderNo={key + 1}
                  />
                ))}
              </>
            )}
            <div className="prize">
              <div className="prize-bg">
                <h6>Price</h6>
                <h4>₹{calculate(tabledata).total}</h4>
              </div>
              <div className="prize-bg">
                <h6>Discount</h6>
                <h4>₹{calculate(tabledata).totalDiscount}</h4>
              </div>
              <div className="prize-bg">
                <h6>Total Price</h6>
                <h4>₹{calculateAllOrders(tabledata)}</h4>
              </div>
            </div>
            <div className="select-variant-container">
              <div className="additem">
                <div
                  className="addremoveitem"
                  style={{ display: "flex", alignItems: "center" }}
                ></div>
                <div className="orderbtn">
                  <Link
                    className="order-complete"
                    onClick={handleOrderComplete}
                  >
                    Order complete
                  </Link>
                  <Link className="order-complete" onClick={handleOrderPunch}>
                    Order Punch
                  </Link>
                </div>
                <div className="orderbtn">
                  <Link
                    className="order-complete"
                    onClick={handleFetchPastOrders}
                  >
                    Past Orders <img src={tick} alt="Settle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCompleteModal}
        onHide={handleCloseModalCompleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                        <img src={popuplogo} alt="popuplogo" />
                    </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {console.log({ tabledata })}
          <div className="complete-order-list">
            {tabledata?.order_info?.map((order, orderIndex) => (
              <>
                <h3>Sub order {orderIndex + 1}</h3>
                {console.log({ order })}
                {order?.items?.map((subItem, subIndex) =>
                  ["None", "Checkout"].includes(subItem.combo) ? (
                    <div key={subIndex} className="accordiabsubIteminfo">
                      <div className="accordiabinfo-left">
                        <div className="order-name">
                          {tableOrderItem.diet === "N" && (
                            <img src={nonveg} alt="Non-Veg" />
                          )}
                          {tableOrderItem.diet === "V" && (
                            <img src={veg} alt="Veg" />
                          )}
                          {tableOrderItem.diet === "E" && (
                            <img src={egg} alt="Egg" />
                          )}
                          <h5>{subItem?.items[0].item_name}</h5>
                        </div>
                        <div className="order-prize">
                          <p>₹{subItem?.items[0].price}</p>
                        </div>
                      </div>
                      <div className="accordiabinfo-right">
                        <div className="countitems">
                          <p>{subItem?.qty}</p>
                        </div>
                        <div className="order-prize">
                          <p>₹{subItem?.items[0].price}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="combo-page">
                      <div className="combo-info">
                        {subItem.items &&
                          subItem.items.map((i, xi) => (
                            <div key={xi} className="accordiabsubIteminfo">
                              <div className="accordiabinfo-left">
                                <div className="order-name">
                                  <img src={nonveg} alt="Non-Veg" />
                                  <h5>{i?.item_name}</h5>
                                </div>
                                <div className="order-prize">
                                  <p>₹{i?.price}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="combo-total">
                        <div className="accordiabinfo-right">
                          <div className="countitems">
                            <p>{subItem?.qty}</p>
                          </div>
                          <div className="order-prize">
                            <p>
                              ₹
                              {(subItem.price - subItem.discount) * subItem.qty}{" "}
                              <del>{subItem.price}</del>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            ))}
          </div>
          <div className="prize mt-2">
            <div className="prize-bg">
              <h6>Price</h6>
              <h4>₹{calculateCompleteDiscount(tabledata).total}</h4>
            </div>
            <div className="prize-bg">
              <h6>Discount</h6>
              <h4>₹{calculateCompleteDiscount(tabledata).totalDiscount}</h4>
            </div>
            <div className="prize-bg">
              <h6>Total paid amount</h6>
              <h4>₹{calculateCompleteDiscount(tabledata).totalPainAmount}</h4>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModalCompleteModal}>Continue</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MainContent;
