import React, { useEffect, useState } from "react";
import { Accordion, Image } from "react-bootstrap";
import veg from "../../assets/veg.svg";
import nonveg from "../../assets/nonveg.svg";
import egg from "../../assets/egg.svg";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
function PastOrder({ punchedItems, isTable, orderNo }) {
  const [orders, setOrders] = useState([]);
  const {menu} = useSelector(state=>state.food)
  // const { tableOrderItem } = useSelector((state) => state.table);

  useEffect(() => {
    console.log({ punchedItems });
  
    // Check if punchedItems is defined and is an array
    if (punchedItems && Array.isArray(punchedItems)) {
      // Sort the array using the traditional sort method
      setOrders(
        [...punchedItems].sort((a, b) => new Date(b.time) - new Date(a.time))
      );
    }
  }, [punchedItems]);
  
  const calculate = (data) => {
    console.log({ data })
    let total = 0;
    let totalDiscount = 0;
    if (
      data?.length > 0
    ) {
      const subtotal = data
        .map((subOrder) => {
          console.log({ subOrder });
          totalDiscount += subOrder.subOrder_discount;
          total += subOrder.subOrder_total;
        })
        .reduce((acc, curr) => acc + curr, 0);

      // setSubtotal(subtotal);
    }
    return { total, totalDiscount };
  };
  const calculateAllOrders = (data) => {
    let total = 0;
    let totalDiscount = 0;
    if (
      data?.length > 0
    ) {
      const subtotal = data
        .map((subOrder) => {
          console.log({ subOrder });
          totalDiscount += subOrder.subOrder_discount;
          total += subOrder.subOrder_total;
        })
        .reduce((acc, curr) => acc + curr, 0);

      // setSubtotal(subtotal);
    }
    return total - totalDiscount;
  };

  console.log({ punchedItems })
  return (
    <>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        {isTable ? <h3>Order {orderNo}</h3> : ""}
        {Array.isArray(orders) &&
          orders?.map((order, index) => (
            <Accordion.Item eventKey="0">
              {console.log({ order })}
              <Accordion.Header>
                {isTable ? "Sub Order" : "Order"} {order.sub_order_id}
              </Accordion.Header>
              <Accordion.Body>
                <ul className="pastorder-list">
                  {order?.items?.map((item, index) =>
                    ["None", "Checkout"].includes(item.combo) ? (
                      <li key={index}>
                        <div className="pastorder-item">
                          <div className="itemmaindetail">
                            <div className="itemsubdetail">
                            <div className="order-name">
                            <img
                              src={item.items[0]?.diet === "N" ? nonveg : item.items[0]?.diet === "V" ? veg : egg}
                              alt="Non-Veg"
                            />
                              <h5>{item?.items[0]?.item_name}</h5>
                              </div>
                              <div className="order-prize">
                                <p>{item?.price}</p>
                              </div>
                              {item?.items[0]?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {item?.items[0]?.add_ons?.map((i, inx) => (
                                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                    <span> - Price: {i.price}</span>
                                                </p>
                                            ))}
                                            </div>}
                                        {!isEmpty(item?.items[0]?.options) && <div className="OrderDetailsSubInfo">
                                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {item?.items[0]?.options && Object.entries(item.items[0].options).map(([optionName, optionDetails], idx) => (
                                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                                <span>{optionName}:</span> 
                                                <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                <span> - Price: {optionDetails.price}</span>
                                            </p>
                                            ))}
                                        </div>}
                            </div>
                            <div className="accordiabinfo-right">
                              <div className="countitems">
                                <span>{item.qty}</span>
                              </div>
                              <p>₹{item.price}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <>
                        <div className="combo-page">
                            {console.log({ item })}
                          <div className="combo-info">
                            {Array.isArray(item?.items) &&
                              item?.items?.map((subitem, subindex) => (
                                <li key={subindex}>
                                  <div className="pastorder-item">
                                    <div className="itemmaindetail">
                                      <div className="itemsubdetail">
                                        <div className="order-name">
                                          <img
                                            src={subitem.diet === "N" ? nonveg : subitem.diet === "V" ? veg : egg}
                                            alt="Non-Veg"
                                          />
                                          <h5>{subitem?.item_name}</h5>
                                        </div>
                                        <div className="order-prize">
                                          <p>₹{subitem.price}</p>
                                        </div>
                                        {subitem?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {subitem?.add_ons?.map((i, inx) => (
                                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                                    <span> - Price: {i.price}</span>
                                                </p>
                                            ))}
                                            </div>}
                                        {!isEmpty(subitem?.options) && <div className="OrderDetailsSubInfo">
                                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {subitem?.options && Object.entries(item.items[0].options).map(([optionName, optionDetails], idx) => (
                                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                                <span>{optionName}:</span> 
                                                <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                                <span> - Price: {optionDetails.price}</span>
                                            </p>
                                            ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </div>
                          <div className="accordiabinfo-right">
                            <div className="countitems">
                              <span>{item.qty}</span>
                            </div>
                            <p>₹{(item.price-item.discount) * item.qty} <del>{item.price}</del></p>
                            {/* <p>₹{item.price}</p> */}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          ))}
          {punchedItems.length > 0 && 
            <div className="prize">
              <div className="prize-bg">
                <h6>Price before discount</h6>
                <h6>₹{calculate(orders).total}</h6>
              </div>
              <div className="prize-bg">
                <h6>Discount</h6>
                <h6>₹{calculate(orders).totalDiscount}</h6>
              </div>
              <div className="prize-bg">
                <h6>Total Price</h6>
                <h6>₹{calculateAllOrders(orders)}</h6>
              </div>
            </div>}
      </Accordion>
      <div className="horizontal-line"></div>
    </>
  );
}

export default PastOrder;
